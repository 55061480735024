const classes = {
  todayActiveClass: 'is-today-active',
  tomorrowActiveClass: 'is-tomorrow-active',
  accordionActiveClass: 'is-active',
  isAnimatedClass: 'is-animated'
}

const selectors = {
  todayButtonSelector: '.block-interactive-infographics__switch.is-today',
  tomorrowButtonSelector: '.block-interactive-infographics__switch.is-tomorrow',
  accordionSelector: '.interactive-infographics-accordion',
  accordionButtonSelector: '.interactive-infographics-accordion__button',
}

const svgSelectors = {
  car: '.car',
  bus: '.bus',
  laptopLines: '.laptop-graph-line'
}

function cloud (cloudEl) {
  let position = 0;
  const length = cloudEl.getBoundingClientRect().width;
  const finalPosition = length * 3;
  const step = finalPosition / 1500 + 0.1;

  return {
    calculateOpacity() {
      const positionPercentage = position / finalPosition * 100;

      if (positionPercentage < 5) {
        return positionPercentage / 5;
      }

      if (positionPercentage > 95) {
        return (100 - positionPercentage) / 5;
      }

      return 1;
    },
    render() {
      if (!cloudEl) return;

      if (position >= finalPosition) {
        position = 0;
      } else {
        position += step;
      }

      const nextPositionStr = (-1 * position).toFixed(2);

      cloudEl.style.opacity = this.calculateOpacity();
      cloudEl.setAttribute('transform', `matrix(1, 0, 0, 1, ${nextPositionStr}, 0)`)
    }
  }
}

class InteractiveInfographics extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.abortController = new AbortController();
    this.abortSignal = this.abortController.signal;

    this.todayButton = this.querySelector(selectors.todayButtonSelector);
    this.tomorrowButton = this.querySelector(selectors.tomorrowButtonSelector);
    this.accordions = this.querySelectorAll(selectors.accordionSelector);
    this.accordionsList = this.accordions ? [...this.accordions] : [];

    this.bindEvents();
    this.setAccordionsOffset();
    this.animateClouds();
    this.animateStaticSvgs();
  }

  bindEvents() {
    window.addEventListener('resize', () => this.setAccordionsOffset(), {signal: this.abortSignal});
    this.todayButton?.addEventListener('click', () => this.showToday(), {signal: this.abortSignal});
    this.tomorrowButton?.addEventListener('click', () => this.showTomorrow(), {signal: this.abortSignal});
    this.accordionsList.forEach(accordion => {
      const button = accordion.querySelector(selectors.accordionButtonSelector);

      if (button) {
        button.addEventListener('click', () => {
          const isAccordionActive = accordion.classList.contains(classes.accordionActiveClass);

          this.clearActiveAccordions();
          accordion.classList.toggle(classes.accordionActiveClass, !isAccordionActive);
        }, {signal: this.abortSignal})
      }
    })
  }

  clearActiveClasses() {
    this.classList.remove(classes.todayActiveClass);
    this.classList.remove(classes.tomorrowActiveClass);
  }

  clearActiveAccordions () {
    this.accordionsList.forEach(accordion => accordion.classList.remove(classes.accordionActiveClass));
  }

  animateStaticSvg(selector) {
    if (!selector) return;

    const elements = this.querySelectorAll(selector);
    const elementsArray = [...elements];

    elementsArray.forEach(el => {
      el.classList.remove(classes.isAnimatedClass);
    })

    setTimeout(() => {
      elementsArray.forEach(el => {
        el.classList.add(classes.isAnimatedClass);
      })
    }, 50)
  }

  animateStaticSvgs() {
    Object.keys(svgSelectors).forEach(key => {
      const selector = svgSelectors[key];

      this.animateStaticSvg(selector);
    })
  }

  setActiveClass(className) {
    this.clearActiveClasses();
    this.clearActiveAccordions();
    this.animateStaticSvgs();
    this.classList.add(className);
  }

  showToday() {
    this.setActiveClass(classes.todayActiveClass);
  }

  showTomorrow() {
    this.setActiveClass(classes.tomorrowActiveClass);
  }

  setAccordionsOffset () {
    this.accordionsList.forEach(accordion => {
      const button = accordion.querySelector(selectors.accordionButtonSelector);

      if (button) {
        const buttonRect = button.getBoundingClientRect();
        const offset = -1 * buttonRect.height / 2;

        accordion.style.setProperty('--vertical-offset', `${offset}px`)
      }
    })
  }

  animateClouds() {
    const clouds = this.querySelectorAll('.cloud');
    const cloudsArray = [...clouds].map(cloudEl => cloud(cloudEl));

    (function frame () {
      requestAnimationFrame(() => {
        cloudsArray.forEach(cloud => {
          cloud.render();
        });

        frame();
      })
    })()
  }

  disconnectedCallback() {
    this.abortController.abort();
  }
}

customElements.define('interactive-infographics', InteractiveInfographics);
